.serial-port {
  font-family: 'Courier New', monospace;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
  background-color: #0c0c0c;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.serial-port .water-mark {
  width: 100%;
  overflow: hidden;
  height: 100%;
  background-color: #000000ea;
}
@media only screen and (max-width: 600px) {
  .serial-port  {
    background-size: 80%;
  }
}

.serial-port .water-mark .left {
  text-align: leftyy;
}

.serial-port .water-mark .right {
  text-align: right;
}

.serial-port .output {
  width: 100%;
  height: calc(100vh - 2em);
  overflow-y: hidden;
}

.serial-port .output ::selection {
  background-color: #ffcc00;
  color: #000;
}

.serial-port .output textarea {
  width: 100%;
  background-color: transparent;
  border: 0;
  color: #ddd;
  resize: 'none';
  height: calc(100vh - 5em);
}

.serial-port .output textarea::-webkit-scrollbar {
  width: 0.3em;
  background-color: #050b50;
}
 
.serial-port .output textarea::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
.serial-port .output textarea::-webkit-scrollbar-thumb {
  background-color: #1d56d2;
  outline: 1px solid #1d56d2;
}

.serial-port .output-time {
  color: #444;
}

.serial-port .output-user {
  color: #777;
}

.serial-port .input-box {
  background-color: #000;
  position: relative;
  bottom: 0;
  height: 2em;
}
.serial-port input,
.serial-port .form-control {
  color: #ddd;
  border: 0;
  background: #000;
}

.serial-port input::placeholder,
.serial-port .form-control::placeholder {
  /* color: #3DCBF2; */
  color: #444;
}

.serial-port .placeholder-light::placeholder {
  /* color: #3DCBF2; */
  color: #fff;
}


.serial-port input:focus,
.serial-port .form-control:focus {
  border: 0;
  outline: none;
  box-shadow: none;
  background: #000;
  color: #fff;
}

.serial-port .btn-option {
  background-color: transparent;
  color: #aaa;
  font-size: 20px;
  border: 0;
}

.serial-port .btn-option:hover {
  color: #fff;
}

.serial-port .no-select {
  user-select: none;
}

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

.serial-port .blink {
  animation: blink 1s infinite;
}

.serial-port .top-bar {
  color: #888;
  width: 100%;
  padding: 0 15px;
}


/* ESTILOS PARA LAS DIFERENTES CLASES DE TEXTO EN LA TERMINAL*/

.serial-port .output {
  padding-top: 45px;
  color: #777;
}

.serial-port .Tx-Data {
  color: #fff;
}

.serial-port .Rx-Data {
  color: #00ffb3;
}

.serial-port .info {
  color: rgb(64, 192, 192);
}

.serial-port .error {
  color: #900;
}




/* ESTILOS PARA DRAWER DE OPCIONES*/


.drawer-options-header {
  padding-top: 10px;
}

.drawer-options-content::-webkit-scrollbar {
  width: 0.1em;
  height: 0.1em;
  background-color: #050b50;
}
 
.drawer-options-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
.drawer-options-content::-webkit-scrollbar-thumb {
  background-color: #1d56d2;
  outline: 1px solid #1d56d2;
}

.drawer-options {
  text-align: center;
  margin: 0;
  padding-left: 0;
  height: 400px;
  overflow: hidden;
  color: #fff;
  background-color: #222;
}

.drawer-options-menu {
  background-color: #222;
  min-width: 80px;
}

.drawer-options-menu ul {
  margin: 0;
  padding: 0;
}

.drawer-options-menu li {
  list-style: none;
  text-align: center;
}

.drawer-options-menu li button {
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: transparent;
  border: 0;
  color: #fff;
}

.drawer-options-menu li button:hover {
  background-color: #050b5099;
  color: #1d56d2;
}

.drawer-options-menu li button.active {
  background-color: #050b50;
  color: #1d56d2;
}

.drawer-options-content {
  background-color: #333;
  border: solid 2px #444;
  max-height: 330px;
  min-height: 330px;
  max-width: calc(100% - 95px);
  min-width: calc(100% - 95px);
  overflow: auto;
  margin-bottom: 50px;
}

.drawer-options-content .children {
  padding: 10px;
}

.drawer-options-content .children .title {
  text-align: left;
}

.drawer-options-content .children .content {
  text-align: left;
}